/* eslint-disable prefer-const */
import { Dialog, DialogContent, DialogTitle, Radio } from '@mui/material';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PiTag } from '../../../../../corelogic/domain/models/pitag/pitag.model';
import { RootState } from '../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../corelogic/redux/site/site.slice';
import styles from './editTagsPopin.module.scss';
import { CustomButton, StripedDataGrid } from '../../../../../util/functionUtil';
import { GridColDef } from '@mui/x-data-grid';
import { COLUMN_LABEL_EDIT_TAGS } from '../type';
import CustomToolbar from './customToolbar';

type Props = { isOpen: boolean; handleClose: (publish?: boolean) => void; newTags: PiTag[] };

const EditTagsPopin: React.FC<Props> = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { site } = useSelector((state: RootState) => state.site);
  const intl = useIntl();
  let refTags = useRef([...site.tags]);

  const [tagEnable, setTagEnable] = useState(
    refTags.current.map((_, index) => {
      return Boolean(refTags.current[index].tag !== undefined && refTags.current[index].tag !== '');
    }),
  );

  const getIndexThroughName = (name: string): number => {
    return refTags.current.findIndex(tag => tag.name === name);
  };

  function changeConstant(event: ChangeEvent<HTMLInputElement>, index: number): void {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      constant: parseInt(event.target.value),
      tag: '',
      lastModification: new Date().toISOString(),
    };
    refTags.current = copyRefTags;
  }

  function changeTag(event: ChangeEvent<HTMLInputElement>, index: number): void {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      tag: event.target.value.trim(),
      constant: null,
      lastModification: new Date().toISOString(),
    };
    refTags.current = copyRefTags;
  }

  function changeLowerBound(event: ChangeEvent<HTMLInputElement>, index: number): void {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      lowerBound: parseInt(event.target.value),
      lastModification: new Date().toISOString(),
    };
    refTags.current = copyRefTags;
  }

  function changeUpperBound(event: ChangeEvent<HTMLInputElement>, index: number): void {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      upperBound: parseInt(event.target.value),
      lastModification: new Date().toISOString(),
    };
    refTags.current = copyRefTags;
  }

  function changeDefault(event: ChangeEvent<HTMLInputElement>, index: number): void {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      default: parseInt(event.target.value),
      lastModification: new Date().toISOString(),
    };
    refTags.current = copyRefTags;
  }

  const handleToggle = (index: number) => {
    resetOnToggleChange(index);
    setTagEnable(prevArray => {
      const newArray = [...prevArray];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };

  const resetOnToggleChange = (index: number) => {
    let copyRefTags = [...refTags.current];
    copyRefTags[index] = {
      ...copyRefTags[index],
      constant: null,
      tag: '',
    };
    refTags.current = copyRefTags;
  };

  const updateSiteTags = () => {
    const updateBodyTags = { tags: refTags.current };
    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: updateBodyTags }));
    handleClose(true);
  };

  const columns: GridColDef[] = [
    {
      field: COLUMN_LABEL_EDIT_TAGS.NAME,
      headerName: intl.formatMessage({ id: 'tagsMapping.reference' }),
      sortable: false,
      filterable: true,
      flex: 1.5,
      renderCell: params => <FormattedMessage id={'tags.' + params.row.name} />,
    },
    {
      field: COLUMN_LABEL_EDIT_TAGS.TAG,
      headerName: intl.formatMessage({ id: 'tagsMapping.pitag' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const index = getIndexThroughName(params.row.name);
        return (
          <Fragment>
            <Radio checked={tagEnable[index]} onClick={() => handleToggle(index)}></Radio>
            <input
              id={'tag' + index}
              onChange={e => changeTag(e, index)}
              disabled={!tagEnable[index]}
              defaultValue={params.row.tag}
              type="string"
            ></input>
          </Fragment>
        );
      },
    },
    {
      field: COLUMN_LABEL_EDIT_TAGS.CONSTANT,
      headerName: intl.formatMessage({ id: 'tagsMapping.constant' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const index = getIndexThroughName(params.row.name);
        return (
          <Fragment>
            <Radio checked={!tagEnable[index]} onClick={() => handleToggle(index)}></Radio>
            <input
              id={'constant' + index}
              onChange={e => changeConstant(e, index)}
              disabled={tagEnable[index]}
              defaultValue={params.row.constant ?? ''}
              type="number"
            ></input>
          </Fragment>
        );
      },
    },
    {
      field: COLUMN_LABEL_EDIT_TAGS.LOWER_BOUND,
      headerName: intl.formatMessage({ id: 'tagsMapping.lowerBound' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const index = getIndexThroughName(params.row.name);
        return (
          <Fragment>
            <input
              id={'lowerBound' + index}
              onChange={e => changeLowerBound(e, index)}
              defaultValue={params.row.lowerBound ?? ''}
              type="number"
            ></input>
          </Fragment>
        );
      },
    },
    {
      field: COLUMN_LABEL_EDIT_TAGS.UPPER_BOUND,
      headerName: intl.formatMessage({ id: 'tagsMapping.upperBound' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const index = getIndexThroughName(params.row.name);
        return (
          <Fragment>
            <input
              id={'upperBound' + index}
              onChange={e => changeUpperBound(e, index)}
              defaultValue={params.row.upperBound ?? ''}
              type="number"
            ></input>
          </Fragment>
        );
      },
    },
    {
      field: COLUMN_LABEL_EDIT_TAGS.DEFAULT,
      headerName: intl.formatMessage({ id: 'tagsMapping.default' }),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: params => {
        const index = getIndexThroughName(params.row.name);
        return (
          <Fragment>
            <input
              id={'default' + index}
              onChange={e => changeDefault(e, index)}
              defaultValue={params.row.default ?? ''}
              type="number"
            ></input>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Dialog
      className={styles.dialogContainer}
      onClose={() => handleClose(false)}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '90%', width: '100%' },
      }}
    >
      <DialogTitle>
        <div className={styles.titleAndButtonsPopin}>
          <div>
            <FormattedMessage id="popin.editTags" />
          </div>
          <div className={styles.buttonContainerTags}>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="outlined"
                type="button"
                onClick={() => handleClose(false)}
                color="primary"
              >
                <FormattedMessage id="button.cancel" />
              </CustomButton>
            </div>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="contained"
                type="button"
                onClick={updateSiteTags}
                color="primary"
              >
                <FormattedMessage id="button.publish" />
              </CustomButton>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <StripedDataGrid
          sortingOrder={['desc', 'asc']}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [],
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            borderRadius: '16px',
          }}
          rows={refTags.current.filter(tag => tag.name != 'timestamp')}
          columns={columns}
          getRowId={row => row.name}
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          pageSizeOptions={[10]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditTagsPopin;
